import { ImageProps } from 'next/image';
import co_owned_forest from '@/lib/icons/co_owned_forest.svg';
import farm from '@/lib/icons/farm.svg';
import forest from '@/lib/icons/forest.svg';
import home from '@/lib/icons/home.svg';
import leisure_home from '@/lib/icons/leisure_home.svg';
import plot from '@/lib/icons/plot.svg';

export type StaticImport = Exclude<ImageProps['src'], string>;

const icons = {
  co_owned_forest: co_owned_forest as StaticImport,
  farm: farm as StaticImport,
  forest: forest as StaticImport,
  home: home as StaticImport,
  leisure_home: leisure_home as StaticImport,
  plot: plot as StaticImport,
};

export default icons;
