import { Flex, LinkBox, LinkOverlay, Grid, Text } from '@chakra-ui/react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import Image from '@/lib/Image';
import { GetPropertyTypeAggregateQuery } from '@/generated/graphql';
import icons from '@/lib/icons';

const PropertyTypeQuickLinks = ({
  propertyTypesAgg,
}: {
  propertyTypesAgg: GetPropertyTypeAggregateQuery['property_type_aggregate']['nodes'];
}) => {
  const { t } = useTranslation(['frontoffice', 'backoffice']);
  return (
    <Grid
      gridTemplateColumns={['1fr', 'repeat(auto-fit, minmax(200px, 1fr))']}
      gridRowGap={[4, 5]}
      gridColumnGap="5"
    >
      {propertyTypesAgg.map(
        ({ value, properties_aggregate: { aggregate } }) => (
          <LinkBox
            key={value}
            pl="5"
            pr="4"
            py="4"
            background="white"
            rounded="md"
            boxShadow="md"
            _hover={{ boxShadow: 'lg' }}
            transition=".2s box-shadow"
          >
            <Flex alignItems="center" height="100%">
              <Flex mr={2} alignSelf="center">
                <Image
                  src={icons[value as keyof typeof icons]}
                  alt={t(`backoffice:property_type_values.${value}`)}
                  role="presentation"
                />
              </Flex>
              <Flex flexDir="column">
                <Text
                  fontWeight="600"
                  fontSize="sm"
                  whiteSpace="nowrap"
                  lineHeight="1.2"
                >
                  <Link
                    href={`/osta?property_type=${value}`}
                    key={value}
                    passHref
                  >
                    <LinkOverlay>
                      {t(`backoffice:property_type_values.${value}`)}
                    </LinkOverlay>
                  </Link>
                </Text>
                <Text fontSize="sm">
                  {aggregate?.count} {t('qty', 'kpl')}
                </Text>
              </Flex>
            </Flex>
          </LinkBox>
        ),
      )}
    </Grid>
  );
};

export default PropertyTypeQuickLinks;
