import NextImage, { ImageLoaderProps, ImageProps } from 'next/image';
import { useConfig } from '@/config';

const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#333" offset="20%" />
      <stop stop-color="#222" offset="50%" />
      <stop stop-color="#333" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#333" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string) =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str);

export const defaultBlurUrl = `data:image /svg + xml; base64, ${toBase64(
  shimmer(700, 475),
)} `;

const assetPrefix = process.env.NEXT_PUBLIC_ASSET_PREFIX
  ? `${process.env.NEXT_PUBLIC_ASSET_PREFIX}`
  : '';

export const mtImageLoader =
  (hostUrl: string) =>
  ({ src, width, quality }: ImageLoaderProps) => {
    if (typeof src === 'string' && src.startsWith('/')) {
      return `${assetPrefix}/_next/image?url=${hostUrl}${src}&w=${width}&q=${
        quality || 70
      }`;
    }
    return `${assetPrefix}/_next/image?url=${src}&w=${width}&q=${
      quality || 70
    }`;
  };

const Image = ({ blurDataURL, ...rest }: ImageProps) => {
  const { PUBLIC_HOST_URL } = useConfig();
  return (
    <NextImage
      loader={mtImageLoader(PUBLIC_HOST_URL)}
      objectFit="cover"
      objectPosition="50% 50%"
      {...rest}
      blurDataURL={blurDataURL || defaultBlurUrl}
    />
  );
};

export default Image;
